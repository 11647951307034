import React, { useState, useEffect } from 'react';
import Language from '../../language/language.json';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import  de  from 'date-fns/locale/de';
import en  from 'date-fns/locale/en-US';
import { format } from 'date-fns';

function isBase64DataUri(str) {
    if (!str.startsWith('data:')) {
        return false;
    }

    const commaIndex = str.indexOf(',');
    if (commaIndex === -1) {
        return false;
    }

    const encodedData = str.slice(commaIndex + 1);
    return isBase64(encodedData);
}

function isBase64(str) {
    try {
        return window.btoa(window.atob(str)) === str;
    } catch (error) {
        return false;
    }
}


function TnQuickInquiry(props) {
    
    const [anreise, setAnreise] = useState('');
    const [abreise, setAbreise] = useState('');
    const [svgMarkup, setSvgMarkup] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [isSending, setIsSending] = useState(false);
    

    useEffect(() => {
        document.documentElement.style.setProperty('--tqi-widget-fabtextcolor', props.fabTextColor);
        document.documentElement.style.setProperty('--tqi-widget-fabbgcolor', props.fabBgColor);
        document.documentElement.style.setProperty('--tqi-widget-fabicon', 'url('+props.fabIcon+')');
        document.documentElement.style.setProperty('--tqi-widget-buttontextcolor', props.buttonTextColor);
        document.documentElement.style.setProperty('--tqi-widget-buttonbgcolor', props.buttonBgColor);
        document.documentElement.style.setProperty('--tqi-widget-inputtextcolor', props.inputTextColor);
        document.documentElement.style.setProperty('--tqi-widget-inputbgcolor', props.inputBgColor);
        document.documentElement.style.setProperty('--tqi-widget-inputbordercolor', props.inputBorderColor);
        document.documentElement.style.setProperty('--tqi-widget-winbgcolor', props.winBgColor);
        document.documentElement.style.setProperty('--tqi-widget-wintextcolor', props.winTextColor);
        document.documentElement.style.setProperty('--tqi-widget-titlebarbgcolor', props.titlebarBgColor);
        document.documentElement.style.setProperty('--tqi-widget-titlebartextcolor', props.titlebarTextColor);
        
        if(isBase64DataUri(props.fabIcon) && props.fabTextColor){
            let svgMarkupString = window.atob(props.fabIcon.split(',')[1]);
            svgMarkupString = svgMarkupString.replace(/(<svg[^>]*\b)stroke="[^"]*"/, `$1stroke="${props.fabTextColor}"`);
            try {
                // Encode the modified SVG markup as a new Base64 Data URI
                const encodedSvg = window.btoa(svgMarkupString);
                const modifiedDataUri = `data:image/svg+xml;base64,${encodedSvg}`;
                document.documentElement.style.setProperty('--tqi-widget-fabicon', 'url('+modifiedDataUri+')');
                setSvgMarkup(modifiedDataUri);
              } catch (error) {
                //console.error('Error encoding SVG as Data URI:', error);
              }
        }

        /*
        var portalElem = document.querySelector('#tnquickinq_calendar_portal');
        var body = document.body;
        if(!portalElem){
            const newElement = document.createElement('div');
            newElement.id = 'tnquickinq_calendar_portal';
            body.appendChild(newElement);
        } 
        */
    }, []);

    useEffect(() => {
        if(!anreise || !abreise){
            setIsDisabled(true);            
        } else {
            setIsDisabled(false);
        }
    }, [anreise, abreise]);

    const [classActive, setClassActive] = useState('');

    const sendInquiry = () =>{
        setIsSending(true);
        window.location.href = `${props.redirectUrl}?anreise=${format(anreise, 'dd.MM.yyyy')}&abreise=${format(abreise, 'dd.MM.yyyy')}`;
    }

    return (
  
            <div className={classActive}>
       
                <div className="body"> 
                
                <div style={{'transform': 'scale(1)',  'overflow': 'hidden'}} className="chat-circle" onClick={() => setClassActive('active')}>
                        <div className="chat-overlay"></div>
                        <img src={svgMarkup} alt="" />
                        
                </div>
                
                <div className="chat-box" style={{'transform': 'scale(0)'}}>
                    <div className="chat-box-header">
                        <div className='chat-box-header-icon'>
                        <img src={svgMarkup} alt="" />
                        
                        </div>
                        <span className='chat-box-title'>{ props.title }</span>
                        <span className="chat-box-toggle" onClick={() => setClassActive('')}>x</span>
                    </div>
                    <div className="chat-box-body">
                        <div className="chat-box-body-content">
                        <DatePicker
                            onChange={(date) => setAnreise(date)}
                            /*withPortal
                            portalId="tnquickinq_calendar_portal"*/
                            showYearDropdown
                            showMonthDropdown
                            scrollableYearDropdown
                            placeholderText={props.labelArriaval}
                            selected={anreise}
                            locale={props.lang === 'en' || props.lang === ' en' ? en : de}
                            dateFormat="dd.MM.yyyy"
                            selectsStart
                            startDate={anreise}
                            endDate={abreise}
                            minDate={new Date()}
                            />
                            <span style={{ 'height': '10px', display: 'block' }}></span>
                             <DatePicker
                            onChange={(date) => setAbreise(date)}
                            /*withPortal
                            portalId="tnquickinq_calendar_portal"*/
                            showYearDropdown
                            showMonthDropdown
                            scrollableYearDropdown
                            placeholderText={props.labelDeparture}
                            selected={abreise}
                            locale={props.lang === 'en' || props.lang === ' en' ? en : de}
                            dateFormat="dd.MM.yyyy"
                            selectsEnd
                            startDate={anreise}
                            endDate={abreise}
                            minDate={anreise}
                            />
                            <span style={{ 'height': '10px', display: 'block' }}></span>
                            <div className='chat-box-body-content-btn'>
                                <button className={(isDisabled ? " disable " : "") + (isSending ? " sending " : "")}  onClick={sendInquiry}>
                                <svg aria-hidden="true" role="status" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                                </svg>
                                    {props.labelButton}
                                </button>
                            </div>
                        </div>
                    </div>
                    
                   
                </div>  
                </div>
            </div>


    )
}

export default TnQuickInquiry;
