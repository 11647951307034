import React from "react";
import ReactDOM from 'react-dom/client';
import reactToWebComponent from "react-to-webcomponent";
import TnQuickInquiry from "./components/TnQuickInquiry";
import PropTypes from 'prop-types';
import styles from './App.css';
import Language from './language/language.json';

const Index = (props) => {
    console.log(props);
    const lang = props.lang ? props.lang : 'de';
    const labelArriaval = props.labelArriaval ? props.labelArriaval : Language[lang].Labelarriaval;//'Anreise';
    const labelDeparture = props.labelDeparture ? props.labelDeparture : Language[lang].Labeldeparture;//'Abreise';    
    const labelButton = props.labelButton ? props.labelButton : Language[lang].Labelbutton;//'Anfrage senden';    
    const redirectUrl = props.redirectUrl ? props.redirectUrl : '';    
    const title = props.title ? props.title : Language[lang].Title;//''; 
    const fabIcon = props.fabIcon ? props.fabIcon : 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIGljb24tdGFibGVyIGljb24tdGFibGVyLWNhbGVuZGFyLWV2ZW50IiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgZmlsbD0ibm9uZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cGF0aCBzdHJva2U9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiPjwvcGF0aD48cGF0aCBkPSJNNCA1bTAgMmEyIDIgMCAwIDEgMiAtMmgxMmEyIDIgMCAwIDEgMiAydjEyYTIgMiAwIDAgMSAtMiAyaC0xMmEyIDIgMCAwIDEgLTIgLTJ6Ij48L3BhdGg+PHBhdGggZD0iTTE2IDNsMCA0Ij48L3BhdGg+PHBhdGggZD0iTTggM2wwIDQiPjwvcGF0aD48cGF0aCBkPSJNNCAxMWwxNiAwIj48L3BhdGg+PHBhdGggZD0iTTggMTVoMnYyaC0yeiI+PC9wYXRoPjwvc3ZnPg=='; 
    const fabTextColor = props.fabTextColor ? props.fabTextColor : '#FFFFFF'; 
    const fabBgColor = props.fabBgColor ? props.fabBgColor : '#5A5EB9'; 
    const buttonTextColor = props.buttonTextColor ? props.buttonTextColor : '#000000'; 
    const buttonBgColor = props.buttonBgColor ? props.buttonBgColor : '#FFFFFF'; 
    const inputTextColor = props.inputTextColor ? props.inputTextColor : '#000000'; 
    const inputBgColor = props.inputBgColor ? props.inputBgColor : '#FFFFFF'; 
    const inputBorderColor = props.inputBorderColor ? props.inputBorderColor : '#CCCCCC'; 
    const winBgColor = props.winBgColor ? props.winBgColor : '#b3af90'; 
    const winTextColor = props.winTextColor ? props.winTextColor : '#FFFFFF'; 
    const titlebarBgColor = props.titlebarBgColor ? props.titlebarBgColor : '#000000'; 
    const titlebarTextColor = props.titlebarTextColor ? props.titlebarTextColor : '#FFFFFF'; 
    
    
    return (

        <div styles={styles}>

            <TnQuickInquiry 
                lang={lang}
                labelArriaval={labelArriaval}
                labelDeparture={labelDeparture}
                labelButton={labelButton}
                redirectUrl={redirectUrl}
                title={title}
                fabIcon={fabIcon}
                fabTextColor={fabTextColor}
                fabBgColor={fabBgColor}
                buttonTextColor={buttonTextColor}
                buttonBgColor={buttonBgColor}
                inputTextColor={inputTextColor}
                inputBgColor={inputBgColor}
                inputBorderColor={inputBorderColor}
                winBgColor={winBgColor}
                winTextColor={winTextColor}
                titlebarBgColor={titlebarBgColor}
                titlebarTextColor={titlebarTextColor}
                ></TnQuickInquiry>
    
        </div>

    );
};


Index.propTypes = {
    lang: PropTypes.string,
    labelArriaval: PropTypes.string,
    labelDeparture: PropTypes.string,
    labelButton: PropTypes.string,
    redirectUrl: PropTypes.string,
    title: PropTypes.string,
    fabIcon: PropTypes.string,
    fabTextColor: PropTypes.string,
    fabBgColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
    buttonBgColor: PropTypes.string,
    inputTextColor: PropTypes.string,
    inputBgColor: PropTypes.string,
    inputBorderColor: PropTypes.string,
    winBgColor: PropTypes.string,
    winTextColor: PropTypes.string,
    titlebarBgColor: PropTypes.string,
    titlebarTextColor: PropTypes.string
}


customElements.define(
    "tn-quick-inquiry",
    reactToWebComponent(Index, React, ReactDOM, {}),
)